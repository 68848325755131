import alipay from '@/assets/alipay.jpg'
import redBookLogo from '@/assets/redBook-black-logo.svg'
import redBookCode from '@/assets/redBook-code.jpg'
import vscLogo from '@/assets/vsc-logo.svg'
import weChat from '@/assets/weChat.jpg'
import InfoPanel from '@/components/InfoPanel'
import { infoPanelStateAtom } from '@/store'
import { InfoPanelType } from '@/typings'
import { recordOpenInfoPanelAction } from '@/utils'
import { EnvelopeIcon } from '@heroicons/react/24/solid'
import { IconBrandWechat, IconCoffee, IconTerminal2 } from '@tabler/icons-react'
import { useAtom } from 'jotai'
import React, { useCallback } from 'react'

const Footer: React.FC = () => {
  const [infoPanelState, setInfoPanelState] = useAtom(infoPanelStateAtom)

  const handleOpenInfoPanel = useCallback(
    (modalType: InfoPanelType) => {
      recordOpenInfoPanelAction(modalType, 'footer')
      setInfoPanelState((state) => {
        return {
          ...state,
          [modalType]: true,
        }
      })
    },
    [setInfoPanelState],
  )

  const handleCloseInfoPanel = useCallback(
    (modalType: InfoPanelType) => {
      setInfoPanelState((state) => {
        return {
          ...state,
          [modalType]: false,
        }
      })
    },
    [setInfoPanelState],
  )

  return (
    <>
      <footer
        className="mt-4 flex flex w-full items-center items-center justify-center justify-center pb-1 text-sm ease-in"
        onClick={(e) => e.currentTarget.blur()}
      >
        <button
          className="cursor-pointer text-gray-500 no-underline hover:no-underline dark:text-gray-400 "
          type="button"
          onClick={(e) => {
            handleOpenInfoPanel('donate')
            e.currentTarget.blur()
          }}
        >
          @ Qwerty Learner & 码奥优学
        </button>
        <a
          className="cursor-pointer pl-2 text-gray-500 no-underline hover:no-underline dark:text-gray-400"
          href="https://beian.miit.gov.cn"
          target="_blank"
          rel="noreferrer"
        >
          蜀ICP备2020037540号
        </a>
        {/*<span className="ml-2 select-none rounded bg-gray-200 px-1 py-0.5 text-xs text-gray-400 dark:bg-gray-800 dark:text-gray-500">*/}
        {/*  Build <span className="select-all">{LATEST_COMMIT_HASH}</span>*/}
        {/*</span>*/}
      </footer>
    </>
  )
}

export default Footer
