﻿import { DictionaryResource } from '@/typings/index'

/**
 * Built-in dictionaries in an array.
 * Why arrays? Because it keeps the order across browsers.
 */
export const dictionaries: DictionaryResource[] = [
  {
    id: 'typing',
    name: '字母键',
    description: '键盘练习：字母键',
    category: '键盘练习',
    url: '/dicts/typing.json',
    length: 62,
    language: 'code',
  },
  {
    id: 'typing-sign',
    name: '数字和符号键',
    description: '键盘练习：数字和符号键',
    category: '键盘练习',
    url: '/dicts/typing-sign.json',
    length: 45,
    language: 'code',
  },
  {
    id: 'cpp-basic',
    name: 'C++ 基础词汇',
    description: 'C++ 基础词汇',
    category: '少儿编程',
    url: '/dicts/cpp-basic.json',
    length: 106,
    language: 'code',
  },
  {
    id: 'cpp-coding',
    name: 'C++ 编程',
    description: 'C++ 代码块练习',
    category: '少儿编程',
    url: '/dicts/cpp-coding.json',
    length: 43,
    language: 'code',
  },
  {
    id: 'python-basic',
    name: 'Python 基础词汇',
    description: 'Python 基础词汇',
    category: '少儿编程',
    url: '/dicts/python-basic.json',
    length: 96,
    language: 'code',
  },
  {
    id: 'python-turtle',
    name: 'Python: Turtle',
    description: 'Python Turtle绘图 API ',
    category: '少儿编程',
    url: '/dicts/python-turtle.json',
    length: 27,
    language: 'code',
  },
  {
    id: 'python-math',
    name: 'Python: math',
    description: 'Python 数学 API ',
    category: '少儿编程',
    url: '/dicts/python-math.json',
    length: 37,
    language: 'code',
  },
  {
    id: 'python-string',
    name: 'Python: string',
    description: 'Python 字符串 API ',
    category: '少儿编程',
    url: '/dicts/python-string.json',
    length: 40,
    language: 'code',
  },
  {
    id: 'san1',
    name: '三年级上',
    description: '人教版三年级上册',
    category: '人教版英语',
    url: '/dicts/PEPXiaoXue3_1_T.json',
    length: 64,
    language: 'en',
  },
  {
    id: 'san2',
    name: '三年级下',
    description: '人教版三年级下册',
    category: '人教版英语',
    url: '/dicts/PEPXiaoXue3_2_T.json',
    length: 72,
    language: 'en',
  },
  {
    id: 'si1',
    name: '四年级上',
    description: '人教版四年级上册',
    category: '人教版英语',
    url: '/dicts/PEPXiaoXue4_1_T.json',
    length: 84,
    language: 'en',
  },
  {
    id: 'si2',
    name: '四年级下',
    description: '人教版四年级下册',
    category: '人教版英语',
    url: '/dicts/PEPXiaoXue4_2_T.json',
    length: 104,
    language: 'en',
  },
  {
    id: 'wu1',
    name: '五年级上',
    description: '人教版五年级上册',
    category: '人教版英语',
    url: '/dicts/PEPXiaoXue5_1_T.json',
    length: 131,
    language: 'en',
  },
  {
    id: 'wu2',
    name: '五年级下',
    description: '人教版五年级下册',
    category: '人教版英语',
    url: '/dicts/PEPXiaoXue5_2_T.json',
    length: 156,
    language: 'en',
  },
  {
    id: 'liu1',
    name: '六年级上',
    description: '人教版六年级上册',
    category: '人教版英语',
    url: '/dicts/PEPXiaoXue6_1_T.json',
    length: 130,
    language: 'en',
  },
  {
    id: 'liu2',
    name: '六年级下',
    description: '人教版六年级下册',
    category: '人教版英语',
    url: '/dicts/PEPXiaoXue6_2_T.json',
    length: 108,
    language: 'en',
  },
  {
    id: 'qi1',
    name: '七年级上',
    description: '人教版七年级上册',
    category: '人教版英语',
    url: '/dicts/PEPChuZhong7_1_T.json',
    length: 392,
    language: 'en',
  },
  {
    id: 'qi2',
    name: '七年级下',
    description: '人教版七年级下册',
    category: '人教版英语',
    url: '/dicts/PEPChuZhong7_2_T.json',
    length: 492,
    language: 'en',
  },
  {
    id: 'ba1',
    name: '八年级上',
    description: '人教版八年级上册',
    category: '人教版英语',
    url: '/dicts/PEPChuZhong8_1_T.json',
    length: 419,
    language: 'en',
  },
  {
    id: 'ba2',
    name: '八年级下',
    description: '人教版八年级下册',
    category: '人教版英语',
    url: '/dicts/PEPChuZhong8_2_T.json',
    length: 466,
    language: 'en',
  },
  {
    id: 'jiu',
    name: '九年级',
    description: '人教版九年级全册',
    category: '人教版英语',
    url: '/dicts/PEPChuZhong9_1_T.json',
    length: 551,
    language: 'en',
  },
  {
    id: 'nce1',
    name: '新概念英语-1',
    description: '新概念英语第一册',
    category: '新概念英语',
    url: '/dicts/NCE_1.json',
    length: 900,
    language: 'en',
  },
  {
    id: 'nce2',
    name: '新概念英语-2',
    description: '新概念英语第二册',
    category: '新概念英语',
    url: '/dicts/NCE_2.json',
    length: 858,
    language: 'en',
  },
  {
    id: 'nce3',
    name: '新概念英语-3',
    description: '新概念英语第三册',
    category: '新概念英语',
    url: '/dicts/NCE_3.json',
    length: 1052,
    language: 'en',
  },
  {
    id: 'nce4',
    name: '新概念英语-4',
    description: '新概念英语第四册',
    category: '新概念英语',
    url: '/dicts/NCE_4.json',
    length: 784,
    language: 'en',
  }
  // ,
  // {
  //   id: 'cet4',
  //   name: 'CET-4',
  //   description: '大学英语四级词库',
  //   category: '英语学习',
  //   url: '/dicts/CET4_T.json',
  //   length: 2607,
  //   language: 'en',
  // },
  // {
  //   id: 'cet6',
  //   name: 'CET-6',
  //   description: '大学英语六级词库',
  //   category: '英语学习',
  //   url: '/dicts/CET6_T.json',
  //   length: 2345,
  //   language: 'en',
  // },
  // {
  //   id: 'gaokao3500',
  //   name: '高考 3500 词',
  //   description: '高考常见词 3500',
  //   category: '英语学习',
  //   url: '/dicts/GaoKao_3500.json',
  //   length: 3893,
  //   language: 'en',
  // }
]

/**
 * An object-map from dictionary IDs to dictionary themselves.
 */
export const idDictionaryMap: Record<string, DictionaryResource> = Object.fromEntries(dictionaries.map((dict) => [dict.id, dict]))
